import React, { useEffect, useState } from 'react';
import { Box, Typography, MenuItem, FormControl, Select, Button, Grid, Paper,Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
   } from '@mui/material';
   import jsPDF from "jspdf";
   import "jspdf-autotable";

const GeneratedResult = () => {
  const [sessions, setSessions] = useState([]);
  const [examTypes, setExamTypes] = useState([]);
  const [session, setSession] = useState('');
  const [examType, setExamType] = useState('');
  const [className, setClassName] = useState('');
  const [classes, setClasses] = useState([]);
  const [section, setSection] = useState('');
  const [sections, setSections] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [studentProfile, setStudentProfile] = useState(null);

 const fetchSessions = async () => {
  try {
    const response = await fetch(
      "https://arizshad-002-site5.ktempurl.com/api/ClassPromotion/GetFinancialYear",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: sessionStorage.getItem("token"),
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    if (Array.isArray(data)) {
      setSessions(data);

      // Calculate the current session year
      const currentYear = new Date().getFullYear();
      const nextYear = currentYear + 1;
      const currentSessionString = `${currentYear}-${nextYear}`;

      // Find the session that matches the current session year
      const currentSession = data.find((item) =>
        item.finanacialYear === currentSessionString
      );

      if (currentSession) {
        setSession(currentSession.financialYearID);
      }
    }
  } catch (error) {
    console.error("Error fetching financial years:", error);
  }
};


  const fetchExamTypes = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL; // Replace with your base URL
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${apiUrl}/Exam/ExamList`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({}), // Pass additional parameters if required
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
  
      if (Array.isArray(data)) {
        // Only map the fields necessary for the dropdown
        setExamTypes(data.map((item) => ({
          id: item.examTypeId,
          name: item.examType,
        })));
      }
    } catch (error) {
      console.error('Error fetching exam types:', error);
    }
  };

  const fetchClasses = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL; // Replace with your base URL
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${apiUrl}/Enquiry/ddlClassName`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({}), // Pass additional parameters if required
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
  
      if (Array.isArray(data)) {
        // Map the data if necessary to fit the dropdown format
        setClasses(data.map((item) => ({
          id: item.classId, // Adjust key as per API response
          name: item.className, // Adjust key as per API response
        })));
      }
    } catch (error) {
      console.error('Error fetching class names:', error);
    }
  };
  
  const fetchSections = async (classId) => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL; // Replace with your base URL
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${apiUrl}/Exam/ddlSection_clsId`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({ classId }), // Send the selected class ID
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
  
      if (Array.isArray(data)) {
        setSections(data.map((item) => ({
          id: item.sectionId, // Adjust key as per API response
          name: item.sectionName, // Adjust key as per API response
        })));
      }
    } catch (error) {
      console.error('Error fetching sections:', error);
    }
  };

  const handleClassChange = (event) => {
    const selectedClass = event.target.value;
    setClassName(selectedClass);
    if (selectedClass) {
      fetchSections(selectedClass);
    } else {
      setSections([]); // Clear sections if no class is selected
    }
  };

  const handleGetResult = async () => {
    if (!session || !examType || !className || !section) {
      alert("Please select all fields!");
      return;
    }
  
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL; // Replace with your base URL
      const token = sessionStorage.getItem('token');
      const response = await fetch(
        "https://arizshad-002-site5.ktempurl.com/api/Result/GetStudentResultsGenerated",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            sessionId: session,
            examTypeId: 0,
            classId: className,
            sectionId: section,
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const data = await response.json();
  
      if (Array.isArray(data)) {
        setResultData(data);
        console.log("Fetched Result Data:", data);
      } else {
        console.error("Unexpected API response format");
      }
    } catch (error) {
      console.error("Error fetching result data:", error);
    }
  };
  
  

  useEffect(() => {
    fetchSessions();
    fetchExamTypes();
    fetchClasses(); // Fetch class data
  }, []);


  const fetchStudentProfile = async (studentId) => {
    try {
        const apiUrl = process.env.REACT_APP_BASE_URL;
        const token = sessionStorage.getItem('token');
        
        // Step 1: Fetch student profile
        const profileResponse = await fetch(`${apiUrl}/Attendance/StudentProfile`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify({ studentId }),
        });

        if (!profileResponse.ok) {
            console.error('Failed to fetch student profile');
            return;
        }
        
        const profileData = await profileResponse.json();
        const studentProfile = profileData.objData;
        setStudentProfile(studentProfile);
        
        // Step 2: Fetch class subjects
        const subjectsResponse = await fetch(`${apiUrl}/Result/GetClassSubjects_Result`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify({ studentId }),
        });
        
        if (!subjectsResponse.ok) {
            console.error('Failed to fetch class subjects');
            return;
        }
        
        const subjectsData = await subjectsResponse.json();
        const subjectNames = subjectsData.map(subject => subject.subjectName);

        // Step 3: Fetch exam criteria
        const examCriteriaResponse = await fetch(`${apiUrl}/Result/GetExamCriteria_result`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify({ studentId }),
        });

        if (!examCriteriaResponse.ok) {
            console.error('Failed to fetch exam criteria');
            return;
        }

        const examCriteriaData = await examCriteriaResponse.json();

        // Step 4: Fetch sub-categories for each exam type
        const subCategoryPromises = examCriteriaData.map(async (exam) => {
            const subCategoryResponse = await fetch(`${apiUrl}/Result/GetExamSubCategory_Result`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: token,
                },
                body: JSON.stringify({ studentId, examTypeId: exam.examCategoryId }),
            });

            if (!subCategoryResponse.ok) {
                console.error(`Failed to fetch sub-category for exam type ${exam.examType}`);
                return [];
            }

            const subCategoryData = await subCategoryResponse.json();
            return {
                examType: exam.examType,
                subCategories: subCategoryData.map(sub => sub.subExam),
            };
        });

        const examHeadersData = await Promise.all(subCategoryPromises);

        // Step 5: Fetch exam results and map them to the respective subheadings and subjects
        const resultsResponse = await fetch(`${apiUrl}/Result/GetExamResults`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: token,
            },
            body: JSON.stringify({ 
                studentId, 
                sessionId: 4, 
                examTypeId: 0, 
                subExamId: 0, 
                subjectId: 0 
            }),
        });

        if (!resultsResponse.ok) {
            console.error('Failed to fetch exam results');
            return;
        }

        const resultsData = await resultsResponse.json();
        const results = resultsData.results;

        // Organize the marks for each subject, exam, and sub-exam
        const marksData = {};
        results.forEach(result => {
            const { subjectName, examType, subExam, obtainedMarks } = result;
            if (!marksData[subjectName]) marksData[subjectName] = {};
            if (!marksData[subjectName][examType]) marksData[subjectName][examType] = {};
            marksData[subjectName][examType][subExam] = obtainedMarks;
        });

        handlePrint(studentProfile, subjectNames, examHeadersData, marksData);
    } catch (error) {
        console.error('API request error:', error);
    }
};
  
  const handlePrint = (student, subjectNames, examHeadersData, marksData) => {
    const doc = new jsPDF();

    // Add Background Color
    doc.setFillColor(240, 240, 240); // Light gray color
    doc.rect(0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height, "F");
    const schoolName = sessionStorage.getItem('organizationName') || 'IDEAL PUBLIC SCHOOL';
    // Add School Header
    doc.setFont("helvetica", "bold");
    doc.setFontSize(30);
    doc.text(schoolName.replace(/['"]+/g, ''), 105, 15, null, null, "center");

    // Add lighter green border below the school name
    doc.setDrawColor(0, 102, 0);
    doc.setLineWidth(1.5);
    doc.line(20, 22, 190, 22);

    // Add School Details
    const address = sessionStorage.getItem('address') ;
    const phoneNo = sessionStorage.getItem('phoneNo') ;
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text(address.replace(/['"]+/g, ''), 105, 30, null, null, "center");
    doc.text("Contact No.: " + phoneNo.replace(/['"]+/g, ''), 105, 36, null, null, "center");

    // Add Progress Report Heading
    doc.setFontSize(16);
    doc.setFont("helvetica", "bold");
    doc.text("Progress Report 2024-2025", 105, 48, null, null, "center");

    // Add Student Name in Center
    doc.setFont("helvetica", "bold");
    doc.setFontSize(19);
    doc.text(`${student.studentName || "N/A"}`, 105, 60, null, null, "center");

    // Add border below student name
    doc.setDrawColor(100, 100, 100); // Light gray border
    doc.setLineWidth(0.5);
    doc.line(50, 62, 160, 62);

    // Add lines at the corners of the page
    doc.setDrawColor(0, 0, 0); // Black color
    doc.setLineWidth(0.5);

    // Top-left corner
    doc.line(5, 5, 25, 5); // Horizontal line
    doc.line(5, 5, 5, 25); // Vertical line

    // Top-right corner
    doc.line(doc.internal.pageSize.width - 5, 5, doc.internal.pageSize.width - 25, 5); // Horizontal line
    doc.line(doc.internal.pageSize.width - 5, 5, doc.internal.pageSize.width - 5, 25); // Vertical line

    // Bottom-left corner
    doc.line(5, doc.internal.pageSize.height - 5, 25, doc.internal.pageSize.height - 5); // Horizontal line
    doc.line(5, doc.internal.pageSize.height - 5, 5, doc.internal.pageSize.height - 25); // Vertical line

    // Bottom-right corner
    doc.line(
        doc.internal.pageSize.width - 5,
        doc.internal.pageSize.height - 5,
        doc.internal.pageSize.width - 25,
        doc.internal.pageSize.height - 5
    ); // Horizontal line
    doc.line(
        doc.internal.pageSize.width - 5,
        doc.internal.pageSize.height - 5,
        doc.internal.pageSize.width - 5,
        doc.internal.pageSize.height - 25
    ); // Vertical line

    // Left Side Details
    doc.setFont("helvetica", "normal");
    doc.setFontSize(13);
    const leftDetails = [
        ["Father's Name:", student.fathersName || "N/A"],
        ["Mother's Name:", student.mothersName || "N/A"],
        ["Contact No.:", student.fathersMobileNo || "N/A"],
        ["Address:", student.address || "N/A"],
    ];

    leftDetails.forEach((detail, index) => {
        doc.text(`${detail[0]}`, 20, 70 + index * 8);
        doc.text(`${detail[1]}`, 55, 70 + index * 8);
    });

    // Right Side Details
    const rightDetails = [
        ["Admission No.:", student.admissionNo || "N/A"],
        ["Class & Section:", `${student.className || "N/A"} - ${student.sectionName || "N/A"}`],
        ["Roll No.:", student.rollNo || "N/A"],
        ["Date of Birth:", student.studentDOB || "N/A"],
    ];

    rightDetails.forEach((detail, index) => {
        doc.text(`${detail[0]}`, 120, 70 + index * 8);
        doc.text(`${detail[1]}`, 155, 70 + index * 8);
    });

    // Prepare Table Data
    const tableHeaders = [
      [{ content: "Subject", rowSpan: 2, styles: { halign: 'center' } }],
      []
  ];

  examHeadersData.forEach(exam => {
    tableHeaders[0].push({ content: exam.examType, colSpan: exam.subCategories.length, styles: { halign: 'center' } });
    exam.subCategories.forEach(subCategory => {
        tableHeaders[1].push({ content: subCategory, styles: { halign: 'center' } });
    });
});

  tableHeaders[0].push({ content: "Total", rowSpan: 2, styles: { halign: 'center' } });
  tableHeaders[0].push({ content: "Grade", rowSpan: 2, styles: { halign: 'center' } });

  const tableData = subjectNames.map(subject => {
    const row = [subject];
    
    examHeadersData.forEach(exam => {
        exam.subCategories.forEach(subCategory => {
            const mark = marksData[subject]?.[exam.examType]?.[subCategory] ?? '--';
            row.push(mark);
        });
    });
    
    row.push('--'); // Total
    row.push('--'); // Grade
    return row;
});

    
    // Add Table with Multi-Row Header and Horizontal Border for Sub-Columns
    doc.autoTable({
      startY: 100, // Adjusted to remove margin
      head: tableHeaders,
      body: tableData,
      styles: { fontSize: 9 }, // Adjusted font size for better fit
      headStyles: {
        fillColor: [22, 160, 133],
        textColor: [255, 255, 255],
        lineWidth: 0.5, // Adds border for sub-columns
      },
      columnStyles: {
        0: { cellWidth: 25 }, // Subject column
        1: { cellWidth: 18 }, // PT-1
        2: { cellWidth: 18 }, // Notebook Term-1
        3: { cellWidth: 20 }, // Half-Yearly Term-1
        4: { cellWidth: 18 }, // Out of 100
        5: { cellWidth: 15 }, // PT-2
        6: { cellWidth: 18 }, // Notebook Term-2
        7: { cellWidth: 19 }, // Annual Term-2
        8: { cellWidth: 18 }, // Out of 200
        9: { cellWidth: 18 }, // Total
        10: { cellWidth: 18 }, // Grade
      },
      theme: "grid",
      tableWidth: "wrap", // Ensures the table wraps within page boundaries
      margin: { left: 8, right: 5 }, // Adjust the margins if necessary
      drawHeaderCell: (cell, data) => {
        if (data.row.section === 1) {
          doc.setDrawColor(0); // Black color for border
          doc.setLineWidth(0.5);
          doc.line(cell.x, cell.y + cell.height, cell.x + cell.width, cell.y + cell.height); // Add horizontal line below sub-columns
        }
      },
    });
    
    // Add Final Remarks Section
    doc.setFont("helvetica", "bold");
    doc.setFontSize(10); // Adjusted font size for final remarks
    const finalY = doc.lastAutoTable.finalY + 10;
    doc.text("Remarks: Outstanding", 20, finalY); // Left alignment for remarks
    doc.text(`Overall Grade: ${String(student.grade || "A")}`, 170, finalY, null, null, "right"); // Right alignment for grade
    
    // Open PDF in a new tab
    const pdfUrl = doc.output("bloburl");
    window.open(pdfUrl, "_blank");
  };
  
  
    
  
  
  const handleSessionChange = (event) => setSession(event.target.value);
  const handleExamTypeChange = (event) => setExamType(event.target.value);
  const handleSectionChange = (event) => setSection(event.target.value);

  return (
    <Paper
      elevation={3}
      style={{ padding: '20px', margin: '20px auto', maxWidth: '900px' }}
    >
      <Typography variant="h4" sx={{ mb: 3, textAlign: 'center' }}>
         Generated Result
      </Typography>

      <Grid container spacing={2} justifyContent="center" sx={{ mb: 2 }}>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <Select
              value={session}
              onChange={handleSessionChange}
              displayEmpty
              sx={{ height: 45 }}
            >
              <MenuItem value="" disabled>
                Select Session
              </MenuItem>
              {sessions.map((item) => (
                <MenuItem key={item.financialYearID} value={item.financialYearID}>
                  {item.finanacialYear}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
        <FormControl fullWidth>
  <Select
    value={examType}
    onChange={handleExamTypeChange}
    displayEmpty
    sx={{ height: 45 }}
  >
    <MenuItem value="" disabled>
      Select Exam Type
    </MenuItem>
    {examTypes.map((item) => (
      <MenuItem key={item.id} value={item.id}>
        {item.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>

        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="center" sx={{ mb: 3 }}>
        <Grid item xs={6}>
        <FormControl fullWidth>
  <Select
    value={className}
    onChange={handleClassChange}
    displayEmpty
    sx={{ height: 45 }}
  >
    <MenuItem value="" disabled>
      Select Class
    </MenuItem>
    {classes.map((item) => (
      <MenuItem key={item.id} value={item.id}>
        {item.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>

        </Grid>
        <Grid item xs={6}>
        <FormControl fullWidth>
  <Select
    value={section}
    onChange={handleSectionChange}
    displayEmpty
    sx={{ height: 45 }}
    disabled={!className} // Disable dropdown if no class is selected
  >
    <MenuItem value="" disabled>
      Select Section
    </MenuItem>
    {sections.map((item) => (
      <MenuItem key={item.id} value={item.id}>
        {item.name}
      </MenuItem>
    ))}
  </Select>
</FormControl>

        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Button
          variant="contained"
          onClick={handleGetResult}
          sx={{ width: '200px', height: 45 }}
        >
          Get Result
        </Button>
      </Box>
      <Box sx={{ mt: 3 }}>
  {resultData.length > 0 ? (
    <Box>
      <Typography variant="h6" sx={{ mb: 2, textAlign: "center" }}>
        Result Data
      </Typography>
      <Table sx={{ border: "1px solid #ccc" }}>
  <TableHead>
    <TableRow sx={{ backgroundColor: "#f5f5f5" }}>
      <TableCell align="center">Roll No</TableCell>
      <TableCell align="center">Student Name</TableCell>
      <TableCell align="center">Total Marks</TableCell>
      <TableCell align="center">Obtained Marks</TableCell>
      <TableCell align="center">Grade</TableCell>
      <TableCell align="center">Action</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {resultData.map((student, index) => (
      <TableRow
        key={index}
        sx={{
          "&:nth-of-type(odd)": { backgroundColor: "#f9f9f9" },
          "&:nth-of-type(even)": { backgroundColor: "#ffffff" },
        }}
      >
        <TableCell align="center">{student.rollNo}</TableCell>
        <TableCell align="center">{student.studentName}</TableCell>
        <TableCell align="center">{student.totalMaxMarks}</TableCell>
        <TableCell align="center">{student.totalObtainedMarks}</TableCell>
        <TableCell align="center">{student.grade}</TableCell>
        <TableCell align="center">
        <Button
  onClick={() => fetchStudentProfile(student.studentId)}
  style={{
    padding: "5px 10px",
    backgroundColor: "#007BFF",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  }}
>
  Print
</Button>

        </TableCell>
      </TableRow>
    ))}
  </TableBody>
</Table>

    </Box>
  ) : (
    <Typography variant="body1" sx={{ textAlign: "center", mt: 2 }}>
      No data to display.
    </Typography>
  )}
</Box>


    </Paper>
  );
};

export default GeneratedResult;
