import React, { useState, useEffect } from "react";
import { Container, Paper, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions  } from "@mui/material";
import { useNavigate } from "react-router-dom";

function ExamSubCategory() {
  const [className, setClassName] = useState([]);
  const [examTypes, setExamTypes] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedClassTypeId, setSelectedClassTypeId] = useState("");
  const [selectedExamCategoryId, setSelectedExamCategoryId] = useState("");
  const [isWeightageDisabled, setIsWeightageDisabled] = useState(false); // New state to control weightage field
  const [openDialog, setOpenDialog] = useState(false); // New state to control dialog visibility
  const [subCategoryIdToDelete, setSubCategoryIdToDelete] = useState(null); // New state to track subCategoryId for deletion


  const [formData, setFormData] = useState({
    subExam: "",
    minMarks: 0,
    maxMarks: 0,
    weightage: 0
  });
  let navigate = useNavigate();

  const fetchClassddl = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${apiUrl}/ClassType/GetClassType`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({}),
      });

      if (response.ok) {
        const responseData = await response.json();

        if (responseData.data !== null) {
          setClassName(responseData);
        } else {
          console.error('No data found for classes');
        }

        if (responseData.msg && responseData.msg !== 'Record Not Found') {
          console.error('API error:', responseData.msg);
        }
      } else {
        console.error('Failed to fetch class data');
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  };

  const fetchExamTypes = async (classTypeId) => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${apiUrl}/ExaminationSubCategory/Examddl_subcategory`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          classTypeId
        }),
      });

      if (response.ok) {
        const responseData = await response.json();

        if (responseData.data !== null) {
          // Filter exam types where isExamModeIndependent = 0
          const filteredExamTypes = responseData.filter(item => item.isExamModeIndependent === 0);
          setExamTypes(filteredExamTypes);

          // Check if any exam type has isExamWeightIndependent = 1
          const hasWeightIndependent = responseData.some(item => item.isExamWeightIndependent === 1);
          setIsWeightageDisabled(hasWeightIndependent); // Set state to disable weightage field
        } else {
          console.error('No data found for exam types');
        }

        if (responseData.msg && responseData.msg !== 'Record Not Found') {
          console.error('API error:', responseData.msg);
        }
      } else {
        console.error('Failed to fetch exam types data');
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  };



  const handleClassTypeChange = (event) => {
    const classTypeId = event.target.value;
    setSelectedClassTypeId(classTypeId);
    fetchExamTypes(classTypeId);
  };
  const handleExamTypeChange = (event) => {
    const examCriteriaId = event.target.value; // Updated to use examCriteriaId instead of examTypeId
    setSelectedExamCategoryId(examCriteriaId); // Store the examCriteriaId in the state
    fetchExamSubCategory(examCriteriaId); // Pass examCriteriaId as argument
  };


  const handleSave = async () => {
    try {
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/ExaminationSubCategory`;
      const token = sessionStorage.getItem('token');
      
      const requestData = {
        examCategoryId: selectedExamCategoryId || 2,
        classTypeId: selectedClassTypeId,
        subExam: formData.subExam,
        minMarks: formData.minMarks,
        maxMarks: formData.maxMarks,
        weightage: formData.weightage || 0
      };

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        alert('Data saved successfully');
        setFormData({
          subExam: "",
          minMarks: 0,
          maxMarks: 0,
          weightage: 0
        });
        fetchExamSubCategory(); // Refresh the table data after saving
      } else {
        console.error('Failed to save data');
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  };

  const handleCancel = () => {
    setFormData({
      subExam: "",
      minMarks: 0,
      maxMarks: 0,
      weightage: 0
    });
  };

   // Function to handle edit action
   const handleEdit = (item) => {
    // Navigate to the update route and send the current row data as 'state'
    navigate(`/updateexamsubcategory/${item.subCategoryId}`, { state: { subCategoryData: item } });
  };

  const handleDeleteClick = (subCategoryId) => {
    setSubCategoryIdToDelete(subCategoryId);
    setOpenDialog(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/ExaminationSubCategory/Id?Id=${subCategoryIdToDelete}`;
      const token = sessionStorage.getItem('token');

      const response = await fetch(apiUrl, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
      });

      if (response.ok) {
        alert('SubCategory deleted successfully');
        setOpenDialog(false);
        fetchExamSubCategory();
      } else {
        console.error('Failed to delete subcategory');
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  };

  const handleDeleteCancel = () => {
    setOpenDialog(false);
  };


  const fetchExamSubCategory = async (examCriteriaId) => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${apiUrl}/ExaminationSubCategory/GetExamBySubCategory`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          examCategoryId: examCriteriaId,
        classTypeId: selectedClassTypeId,
        }),
      });

      if (response.ok) {
        const responseData = await response.json();

        if (responseData.data !== null) {
          setSubCategory(responseData);
        } else {
          setSubCategory([]);
          console.error('No data found for classes');
        }

        if (responseData.msg && responseData.msg == 'Record Not Found') {
          setSubCategory([]);
          alert('No data found for classes');
        }
      } else {
        console.error('Failed to fetch class data');
      }
    } catch (error) {
      console.error('API request error:', error);
    }
  };


  useEffect(() => {
    fetchClassddl();
  }, []);

  return (
    <Container maxWidth="lg" style={{ marginTop: "40px" }}>
      <Paper elevation={3} style={{ padding: "20px", textAlign: "center" }}>
        <Typography variant="h5" style={{ marginBottom: "20px" }}>Exam SubCategory</Typography>

        <FormControl fullWidth style={{ marginBottom: "20px" }}>
          <InputLabel htmlFor="classType">Class Type</InputLabel>
          <Select
            id="classType"
            label="Class Type"
            value={selectedClassTypeId}
            onChange={handleClassTypeChange}
          >
            <MenuItem value=""><em>--Select--</em></MenuItem>
            {className.map((item) => (
              <MenuItem key={item.classTypeId} value={item.classTypeId}>
                {item.classType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ marginBottom: "20px" }}>
          <InputLabel htmlFor="examType">Exam Type</InputLabel>
          <Select
            id="examType"
            label="Exam Type"
            defaultValue=""
            value={selectedExamCategoryId}
            onChange={handleExamTypeChange}
          >
            <MenuItem value=""><em>--Select--</em></MenuItem>
            {examTypes.map((item) => (
              <MenuItem key={item.examCriteriaId} value={item.examCriteriaId}>
                {item.examType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Sub Exam"
          value={formData.subExam}
          onChange={(e) => setFormData({ ...formData, subExam: e.target.value })}
          style={{ marginBottom: "20px" }}
        />

        <TextField
          fullWidth
          label="Min Marks"
          type="number"
          value={formData.minMarks}
          onChange={(e) => setFormData({ ...formData, minMarks: e.target.value })}
          style={{ marginBottom: "20px" }}
        />

        <TextField
          fullWidth
          label="Max Marks"
          type="number"
          value={formData.maxMarks}
          onChange={(e) => setFormData({ ...formData, maxMarks: e.target.value })}
          style={{ marginBottom: "20px" }}
        />

        <TextField
          fullWidth
          label="Weightage"
          type="number"
          value={formData.weightage}
          onChange={(e) => setFormData({ ...formData, weightage: e.target.value })}
          style={{ marginBottom: "20px" }}
          disabled={isWeightageDisabled} // Disable weightage field if required

        />

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" color="success" style={{ marginRight: "6px" }} onClick={handleSave}>
            <b>Save</b>
          </Button>
          <Button variant="contained" color="warning" onClick={handleCancel}>
            <b>Cancel</b>
          </Button>
        </div>

        <Typography variant="h5" style={{ marginTop: "40px" }}>Exam SubCategory Table</Typography>
        <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table>
            <TableHead>
              <TableRow>
                <TableCell>Sub Exam</TableCell>
                <TableCell>Class Type</TableCell>
                <TableCell>Exam Type</TableCell>
                <TableCell>Min Marks</TableCell>
                <TableCell>Max Marks</TableCell>
                <TableCell>Weightage</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subCategory.map((item) => (
                <TableRow key={item.subCategoryId}>
                  <TableCell>{item.subExam}</TableCell>
                  <TableCell>{item.classType}</TableCell>
                  <TableCell>{item.examType}</TableCell>
                  <TableCell>{item.minMarks}</TableCell>
                  <TableCell>{item.maxMarks}</TableCell>
                  <TableCell>{item.weightage}</TableCell>
                  <TableCell>
                    <Button className="mx-2" variant="contained" color="primary" 
                     onClick={() => handleEdit(item)} >Edit</Button>
                    <Button 
                    variant="contained" 
                    color="secondary" 
                    onClick={() => handleDeleteClick(item.subCategoryId)}
                  >
                    Delete
                  </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Dialog open={openDialog} onClose={handleDeleteCancel}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>Are you sure you want to delete this subcategory?</DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error">Yes, Delete</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default ExamSubCategory;
