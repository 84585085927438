import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

const AttendanceCalender = ({ isOpen, handleClose, studentId }) => {
  const [studentProfile, setStudentProfile] = useState(null);
  const [monthsData, setMonthsData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);

  useEffect(() => {
    const fetchStudentProfile = async () => {
      try {
        const apiUrl = process.env.REACT_APP_BASE_URL;
        const token = sessionStorage.getItem("token");
        const response = await fetch(`${apiUrl}/Attendance/StudentProfile`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
          },
          body: JSON.stringify({
            studentId: 5,
          }),
        });
        if (response.ok) {
          const data = await response.json();
          if (data.status === null && data.msg === "Record Not Found") {
            return; // Exit the function if the record is not found
          }
          setStudentProfile(data.objData); // Set the entire objData object received from the API response
          setMonthsData(data.objattendancelist);

          const mappedSummary = data.objattendancelist.map((month) => ({
            month: month.month,
            totalDays: month.totalDays,
            presentCount: month.presentCount,
            absentCount: month.absentCount,
            holidayCount: month.holidayCount,
            recordedDays: month.recordedDays || "-",
            attendancePercentage: month.attendancePercentage || "0.00%",
          }));
          setSummaryData(mappedSummary);
        } else {
          console.error("Failed to fetch student profile");
        }
      } catch (error) {
        console.error("API request error:", error);
      }
    };
    fetchStudentProfile();
  }, []);

  return (
    <div className="my-progress">
      {/* Attendance Record Header */}
      <AppBar
        position="static"
        style={{
          backgroundColor: "#0B1F3D",
          marginTop: "20px",
          textAlign: "center",
          maxWidth: "990px",
          margin: "0 auto",
        }}
      >
        <Toolbar style={{ padding: "8px 16px", minHeight: "50px" }}>
          <Typography variant="h6" component="div">
            Attendance Record
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Attendance Record Table */}
      <TableContainer
        style={{
          marginTop: "20px",
          maxWidth: "1400px",
          margin: "auto",
          width: "950px",
        }}
      >
        <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ border: "1px solid #ddd", backgroundColor: "#f2f2f2" }}
              >
                Month
              </TableCell>
              {[...Array(31).keys()].map((day) => (
                <TableCell
                  key={day + 1}
                  style={{
                    border: "1px solid #ddd",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  {day + 1}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {monthsData.map((month, index) => (
              <TableRow key={month.month}>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
                  }}
                >
                  {month.month}
                </TableCell>
                {[...Array(31).keys()].map((day) => (
                  <TableCell
                    key={day + 1}
                    style={{
                      border: "1px solid #ddd",
                      backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
                    }}
                  >
                    {month[Object.keys(month)[day + 1]]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Summary Table */}
      <AppBar
        position="static"
        style={{
          backgroundColor: "#0B1F3D",
          marginTop: "40px",
          textAlign: "center",
          maxWidth: "990px",
          margin: "0 auto",
        }}
      >
        <Toolbar style={{ padding: "8px 16px", minHeight: "50px" }}>
          <Typography variant="h6" component="div">
            Monthly Summary
          </Typography>
        </Toolbar>
      </AppBar>

      <TableContainer
        style={{ marginTop: "20px", maxWidth: "950px", margin: "auto" }}
      >
        <Table style={{ borderCollapse: "collapse", border: "1px solid #ddd" }}>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ border: "1px solid #ddd", backgroundColor: "#f2f2f2" }}
              >
                Month
              </TableCell>
              <TableCell
                style={{ border: "1px solid #ddd", backgroundColor: "#f2f2f2" }}
              >
                Total Days
              </TableCell>
              <TableCell
                style={{ border: "1px solid #ddd", backgroundColor: "#f2f2f2" }}
              >
                Present
              </TableCell>
              <TableCell
                style={{ border: "1px solid #ddd", backgroundColor: "#f2f2f2" }}
              >
                Absent
              </TableCell>
              <TableCell
                style={{ border: "1px solid #ddd", backgroundColor: "#f2f2f2" }}
              >
                Holidays
              </TableCell>
              <TableCell
                style={{ border: "1px solid #ddd", backgroundColor: "#f2f2f2" }}
              >
                Recorded Days
              </TableCell>
              <TableCell
                style={{ border: "1px solid #ddd", backgroundColor: "#f2f2f2" }}
              >
                Attendance %
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {summaryData.map((summary, index) => (
              <TableRow key={summary.month}>
                <TableCell
                  style={{
                    border: "1px solid #ddd",
                    backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#fff",
                  }}
                >
                  {summary.month}
                </TableCell>
                <TableCell style={{ border: "1px solid #ddd" }}>
                  {summary.totalDays}
                </TableCell>
                <TableCell style={{ border: "1px solid #ddd" }}>
                  {summary.presentCount}
                </TableCell>
                <TableCell style={{ border: "1px solid #ddd" }}>
                  {summary.absentCount}
                </TableCell>
                <TableCell style={{ border: "1px solid #ddd" }}>
                  {summary.holidayCount}
                </TableCell>
                <TableCell style={{ border: "1px solid #ddd" }}>
                  {summary.recordedDays || "-"}
                </TableCell>
                <TableCell style={{ border: "1px solid #ddd" }}>
                  {summary.attendancePercentage}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AttendanceCalender;
