import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Paper,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import dayjs from 'dayjs';

const LeavingCertificate = () => {
  const [studentId, setStudentId] = useState('');
  const [selectedStudentName, setSelectedStudentName] = useState(''); // To display selected student's name
  const [leavingDate, setLeavingDate] = useState('');
  const [reasonForLeaving, setReasonForLeaving] = useState('');
  const [lastClassAttended, setLastClassAttended] = useState('');
  const [remarks, setRemarks] = useState('');
  const [ddlClass, setDdlClass] = useState([]);
  const [ddlStudent, setDdlStudent] = useState([]);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openStudentModal, setOpenStudentModal] = useState(false); // To control student modal

  const fetchStudent = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${apiUrl}/FeeDeposit/SerachAdmissionListMonthly`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({
          classId: "0",
        }),
      });
      if (!response.ok) {
        throw new Error(`Error fetching students: ${response.status}`);
      }
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        throw new Error("Record Not Found");
      }
      setDdlStudent(data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchClass = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem('token');
      const response = await fetch(`${apiUrl}/Enquiry/ddlClassName`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify({}),
      });
      if (!response.ok) {
        throw new Error(`Error fetching classes: ${response.status}`);
      }
      const data = await response.json();
      if (data.data === null && data.msg === "Record Not Found") {
        throw new Error("Record Not Found");
      }
      setDdlClass(data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchClass();
    fetchStudent();
  }, []);

  const handleCancel = () => {
    setStudentId('');
    setSelectedStudentName('');
    setLeavingDate('');
    setReasonForLeaving('');
    setLastClassAttended('');
    setRemarks('');
  };

  const handleSubmit = async () => {
    try {
      const url = process.env.REACT_APP_BASE_URL;
      const apiUrl = `${url}/SLC/generate-slc`;
      const token = sessionStorage.getItem('token');

      const formattedDate = dayjs(leavingDate).format('YYYY-MM-DD');

      const payload = {
        studentId: parseInt(studentId),
        leavingDate: formattedDate,
        reasonForLeaving: reasonForLeaving,
        lastClassAttended: lastClassAttended,
        remarks: remarks
      };

      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: token,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Error generating SLC: ${response.status}`);
      }

      const data = await response.json();
      if (response.ok && data.message === 'SLC generated successfully') {
        setOpenSuccessModal(true);
      }

    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleCloseModal = () => {
    setOpenSuccessModal(false);
    handleCancel();
  };

  const handleViewSLC = () => {
    // Redirect or open the SLC view page
    window.open('/path-to-slc-view', '_blank');
  };

  // Open Student Modal
  const handleOpenStudentModal = () => setOpenStudentModal(true);

  // Select student and close modal
  const handleSelectStudent = (student) => {
    setStudentId(student.studentId);
    setSelectedStudentName(student.studentName); // Display selected student's name
    setOpenStudentModal(false);
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '30px' }}>
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography
          variant="h5"
          gutterBottom
          style={{ textAlign: 'center', marginBottom: '20px' }}
        >
          Leaving Form
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Selected Student"
              value={selectedStudentName}
              fullWidth
              variant="outlined"
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleOpenStudentModal}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Leaving Date"
              type="date"
              value={leavingDate}
              onChange={(e) => setLeavingDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Reason for Leaving"
              value={reasonForLeaving}
              onChange={(e) => setReasonForLeaving(e.target.value)}
              fullWidth
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Last Class Attended</InputLabel>
              <Select
                value={lastClassAttended}
                onChange={(e) => setLastClassAttended(e.target.value)}
                label="Last Class Attended"
              >
                <MenuItem value="">
                  <em>Select</em>
                </MenuItem>
                {ddlClass.map((item) => (
                  <MenuItem key={item.classId} value={item.className}>{item.className}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Remarks"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
              fullWidth
              variant="outlined"
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              sx={{ backgroundColor: '#f44336', '&:hover': { backgroundColor: '#d32f2f' } }}
              color='primary'
              onClick={handleSubmit}
            >
              Generate SLC
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: '#2196f3', '&:hover': { backgroundColor: '#1976d2' } }}
              color='secondary'
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Paper>

      {/* Student Selection Modal */}
      <Dialog open={openStudentModal} onClose={() => setOpenStudentModal(false)} maxWidth="md" fullWidth>
        <DialogTitle>Select Student</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Admission No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Class</TableCell>
                  <TableCell>Section</TableCell>
                  <TableCell>Roll No</TableCell>
                  <TableCell>Father's Name</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ddlStudent.map((student) => (
                  <TableRow key={student.studentId}>
                    <TableCell>{student.admissionNo}</TableCell>
                    <TableCell>{student.studentName}</TableCell>
                    <TableCell>{student.className}</TableCell>
                    <TableCell>{student.sectionName}</TableCell>
                    <TableCell>{student.rollNo}</TableCell>
                    <TableCell>{student.fatherName}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleSelectStudent(student)}
                      >
                        Select
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenStudentModal(false)} color="secondary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openSuccessModal}
        onClose={handleCloseModal}
      >
        <DialogTitle>{"SLC Generated Successfully"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            The School Leaving Certificate has been generated successfully.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleViewSLC} color="primary" variant="contained">
            View SLC
          </Button>
          <Button onClick={handleCloseModal} color="secondary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default LeavingCertificate;
